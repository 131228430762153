import * as XLSX from 'xlsx'
import { enumValuesToTranslate, parseDemandComplianceModules } from './enumHelper'
import { AnswerType, Demand, DemandArea, DemandStatus, DemandSubType, DemandType, QuestionDTO, QuestionStatus, SNIVersion } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { GetQuestions } from '../api/question'
import { t } from '../i18n/i18n'

export enum ExportStatus {
   FETCHING_DEMANDS,
   FETCHING_QUESTIONS,
   GENERATING_EXCEL,
}

const generateRows = (dataContext: IDataContext, allQuestions: QuestionDTO[], demand: Demand, parentDemand: Demand): any[] => {
   const { organisationSizes, organisationTypes, leadershipPerspectives, snis_2007, snis_2025, subjects } = dataContext.state

   const relatedQuestions = allQuestions.filter((question) => question.parentId === demand.id)

   const rows = []
   if (relatedQuestions.length === 0) {
      // If no questions are found for the demand, add a row with empty fields for questions information
      rows.push({
         'Krav-ID': demand.publicId,
         'Krav-benämning': demand.designation,
         Krav: stripHtml(demand.text),
         Kravområde: enumValuesToTranslate(DemandArea, demand.area),
         Kravtyp: enumValuesToTranslate(DemandType, demand.type),
         Underkravtyp: enumValuesToTranslate(DemandSubType, demand.subType),
         'Status krav': enumValuesToTranslate(DemandStatus, demand.status),
         'Fråga-Nr': 'Ingen koppling',
         Fråga: 'Ingen koppling',
         'Org.storlek': '',
         'Org.form': '',
         'SNI-2007': '',
         'SNI-2025': '',
         Process: '',
         Ledningsperspektiv: '',
         Ämne: '',
         Tilläggsmodul: '',
         'Status fråga': '',
         Svarsform: '',
         Webbtext: demand.urlText ?? '',
         Webblänk: demand.url ?? '',
         Version: demand.version,
         Kravnivå: demand.level,
         Överkrav: parentDemand ? parentDemand.designation : '',
      })
   }

   relatedQuestions.forEach((question) => {
      const organisationSizeText =
         question.organisationSizes.length === organisationSizes.length
            ? 'Alla'
            : question.organisationSizes.map((x) => x.organisationSize?.text || '').join(', ')
      const organisationTypeText =
         question.organisationTypes.length === organisationTypes.length
            ? 'Alla'
            : question.organisationTypes.map((x) => x.organisationType?.text || '').join(', ')
      const sni_2007_text =
         question.snIs.filter((x) => x.sni.version === SNIVersion.SNI2007).length === snis_2007.length
            ? 'Alla'
            : question.snIs.map((x) => x.sni?.code || '').join(', ')
      const sni_2025_text =
         question.snIs.filter((x) => x.sni.version === SNIVersion.SNI2025).length === snis_2025.length
            ? 'Alla'
            : question.snIs.map((x) => x.sni?.code || '').join(', ')
      const leadershipPerspectiveText =
         question.leadershipPerspectives.length === leadershipPerspectives.length
            ? 'Alla'
            : question.leadershipPerspectives.map((x) => x.leadershipPerspective?.text || '').join(', ')
      const subjectText = question.subjects.length === subjects.length ? 'Alla' : question.subjects.map((x) => x.subject?.text || '').join(', ')
      const demandComplianceModuleText = () => {
         const modules = parseDemandComplianceModules(question.demandComplianceModules)
         if (modules.length === 0) {
            return ''
         }
         if (modules.length === 10) {
            return 'Alla'
         }
         return modules.map((m) => m || '').join(', ')
      }
      
      rows.push({
         'Krav-ID': demand.publicId,
         'Krav-benämning': demand.designation,
         Kravområde: enumValuesToTranslate(DemandArea, demand.area),
         Kravtyp: enumValuesToTranslate(DemandType, demand.type),
         Underkravtyp: enumValuesToTranslate(DemandSubType, demand.subType),
         'Status krav': enumValuesToTranslate(DemandStatus, demand.status),
         'Fråga-Nr': question.designation,
         Fråga: stripHtml(question.text || 'Ingen koppling'),
         'Org.storlek': organisationSizeText,
         'Org.form': organisationTypeText,
         'SNI-2007': sni_2007_text,
         'SNI-2025': sni_2025_text,
         Process: question.process?.process?.text || 'Ingen koppling',
         Ledningsperspektiv: leadershipPerspectiveText,
         Ämne: subjectText,
         Tilläggsmodul: demandComplianceModuleText() ?? '',
         'Status fråga': enumValuesToTranslate(QuestionStatus, question.status),
         Svarsform: enumValuesToTranslate(AnswerType, question.answerType),
         Webbtext: demand.urlText ?? '',
         Webblänk: demand.url ?? '',
         Version: demand.version,
         Kravnivå: demand.level,
         Överkrav: parentDemand ? parentDemand.designation : '',
      })
   })
   return rows
}
export const excelExporter = async (dataContext: IDataContext, setExportingStatus: React.Dispatch<React.SetStateAction<ExportStatus>>) => {
   try {
      setExportingStatus(ExportStatus.FETCHING_DEMANDS)
      const { demands } = dataContext.state

      setExportingStatus(ExportStatus.FETCHING_QUESTIONS)
      const allQuestions = await GetQuestions()
      setExportingStatus(ExportStatus.GENERATING_EXCEL)
      let rows: any[] = []
      demands
         .sort((a, b) => a.order - b.order)
         .forEach((demand) => {
            rows = [...rows, ...generateRows(dataContext, allQuestions, demand, null)]
            if(demand.children) {
            demand.children
               .sort((a, b) => a.order - b.order)
               .forEach((child) => {
                  rows = [...rows, ...generateRows(dataContext, allQuestions, child, demand)]
                  if(child.children) {
                  child.children
                     .sort((a, b) => a.order - b.order)
                     .forEach((grandChild) => {
                        rows = [...rows, ...generateRows(dataContext, allQuestions, grandChild, child)]
                     })
                  }
               })
            }
         })
      const workbook = XLSX.utils.book_new()
      const ws1 = XLSX.utils.json_to_sheet(rows)
      XLSX.utils.book_append_sheet(workbook, ws1, 'Demands')

      XLSX.writeFile(workbook, 'export.xlsx')
      dataContext.handleMessage(dataContext.setRootState, 'success', t('ExportedSuccess'), '')
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('ExportFail'), error.message)
   } finally {
      setExportingStatus(null)
   }
}

const stripHtml = (html: string): string => {
   const div = document.createElement('div');
   div.innerHTML = html;
   return div.textContent ||div.innerText || '';
}