import React from 'react'
import { OptionOnSelectData, SelectionEvents } from '@fluentui/react-combobox'
import { Field, Dropdown, Option, Text, tokens } from '@fluentui/react-components'
import { Control, Controller } from 'react-hook-form'
import { DropdownOption } from '../../../helpers/enumHelper'
import { t } from '../../../i18n/i18n'

type DropdownProps = {
   label?: string
   options: DropdownOption[]
   ariaLabel?: string
   selectedOptions?: string[]
   disabled?: boolean
   onOptionSelect?: (event: SelectionEvents, data: OptionOnSelectData) => void
   multiselect: boolean
   className?: string
}
export const BasicDropdown = ({ label, options, ariaLabel, selectedOptions, disabled, onOptionSelect, multiselect, className }: DropdownProps) => {
   if (label) {
      return (
         <Field label={label} className={className ?? ''}>
            <Dropdown
               multiselect={multiselect}
               disabled={disabled ?? false}
               aria-label={ariaLabel}
               value={selectedOptions.length > 0 ? selectedOptions.join(', ') : ''}
               selectedOptions={selectedOptions}
               onOptionSelect={onOptionSelect}
               style={{ minWidth: 'auto', width: '100%' }}
            >
               {options.map((x) => (
                  <Option key={x.key} value={x.key.toString()} disabled={x.disabled ? x.disabled : false}>
                     {x.text}
                  </Option>
               ))}
            </Dropdown>
         </Field>
      )
   }

   return (
      <Dropdown
         multiselect={multiselect}
         disabled={disabled ?? false}
         aria-label={ariaLabel}
         value={selectedOptions.length > 0 ? selectedOptions.join(', ') : ''}
         selectedOptions={selectedOptions}
         onOptionSelect={onOptionSelect}
         className={className ?? ''}
         style={{ minWidth: 'auto', width: '100%' }}
      >
         {options.map((x) => (
            <Option key={x.key} value={x.key.toString()} disabled={x.disabled ? x.disabled : false}>
               {x.text}
            </Option>
         ))}
      </Dropdown>
   )
}
type ControlledDropdownProps = {
   name: any
   control: Control<any>
   label: string
   options: DropdownOption[]
   disabled?: boolean
   required?: boolean
   multi?: boolean
}
export const ControlledDropDown = ({ name, control, label, options, disabled, multi, required }: ControlledDropdownProps) => {
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false }}
         render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Field label={label} required={required}>
               <Dropdown
                  multiselect={multi ?? false}
                  value={
                     multi
                        ? value?.map((v:any) => options.find((x) => x.key === v)?.text).filter(Boolean).join(', ') ?? ''
                        : options.find((x) => x.key === value)?.text ?? ''
                  }
                  onOptionSelect={(e, d) => {
                     if (multi) {
                        const selected = d.selectedOptions.map((x) => Number(x))
                        onChange(selected)
                     } else {
                        onChange(d.optionValue ? Number(d.optionValue) : '')
                     }
                  }}
                  selectedOptions={multi ? value?.map(String) ?? [] : value ? [String(value)] : []}
                  disabled={disabled ?? false}
                  style={{ minWidth: 'auto' }}
               >
                  {options.map((x) => (
                     <Option key={x.key} value={x.key.toString()} disabled={x.disabled ? x.disabled : false}>
                        {x.text}
                     </Option>
                  ))}
               </Dropdown>
               <Text style={{ color: tokens.colorStatusDangerForeground1 }}>{error?.message}</Text>
            </Field>
         )}
      />
   )
}
