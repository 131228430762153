import { ILanguage } from '../../interfaces/ILanguage'

export const en: ILanguage = {
   AppHeader: '365 Back Office',
   SignIn: 'Sign in',
   SignOut: 'Sign out',
   WelcomeTo: 'Welcome to',

   Title: 'Title',
   Status: 'Status',
   RelevanceStatus: 'Relevance Status',
   ID: 'ID',
   SfsNo: 'SFS No.',
   Department: 'Department/Authority',
   IssuedAt: 'Issued at',
   ModifiedAt: 'ModifiedAt',
   ModificationRegister: 'Modification register',
   Source: 'Source',
   Content: 'Content',

   ShowLawsWithStatusNo: 'Show laws with status "No"',
   Number: 'Number',
   Subject: 'Subject',
   LeadershipPerspective: 'Management perspective',
   NumberOfLaws: 'Number of laws',
   Modified: 'Modified',
   ModifiedBy: 'Modified by',
   NewDemand: 'New demand',
   ChoosenDemands: 'Choosen demands',
   ChoosenDemandsInfo:
      'These are the choosen and created demands. These are the ones that are visible in 365 Robust. You can add more laws in the "Laws" section',
   WebLink: 'Link',
   Description: 'Description',
   ChooseParagraphsInfo: 'Choose the paragraphs that shall be demands',
   ClearDemand: 'Clear ongoing demand handling',
   ClearDemandText: 'Are you sure you want to clear the ongoing demand handling? This will delete the data and resets the status. It is irreverisble',
   QuestionWillBeDeletedOnContinue:
      'This question will be deleted if you continue. Please save the question before you switch question if you want to save the question',

   //User Message Bar
   LawWithStatusNoCantBeHandled: "A law with status 'no' can't be handled.",
   ChangeStatusToHandleLaw: 'To handle the law, the status must be changed to "yes" or "maybe"',
   LawHasBeenSentToDemandList: 'The law has been sent to the demands & question list.',
   LawCantBeEdited: 'The law can´t be edited.',
   LawHasBeenUpdatedSinceLastUpdate: 'The law has been UPDATED since it last was modified',
   ChangeStatusToUpdateImportStatus: 'Change the relevance status or select "Keep relevance status" to update the import status.',
   LawIsUpdated: 'The law is updated',
   PleaseReviewDemands: 'Please review the demands.',
   NoResultByFilter: 'No results found for the selected filters',
   AdjustFilterToFindWhatYoureLookingFor: "Try adjusting your filters to find what you're looking for",

   // Nav
   Dashboard: 'Dashboard',
   Home: 'Home',
   CustomerRegister: 'Customer register',
   DemandQuestionList: 'List of demands and questions',
   LawsImport: 'Import of laws',
   KnowledgeBankFormCustomer: 'Knowledge bank for customer',
   SystemSettings: 'System settings',

   // Status
   Yes: 'Yes',
   No: 'No',
   Maybe: 'Maybe',
   Demand: 'Demand',
   OngoingDemand: 'Ongoing demand',
   Updated: 'Updated',
   Revoked: 'Revoked',
   New: 'New',
   All: 'All',
   Empty: '(Empty)',
   Unhandled: 'Unhandled',
   Definite: 'Definite',
   Ongoing: 'Ongoing',
   Reviewing: 'Reviewing',
   Resting: 'Resting',
   Archived: 'Archived',
   SavedButNotSure: '',
   Archive: 'Archive',

   // Laws
   ImportedLaws: 'Imported laws',

   // Demand
   SubType: 'Sub type',
   EUDirective: 'EU directive',
   Law: 'Law',
   Paragraph: 'Paragraph',
   ISOStandard: 'ISO standard',
   Ordinance: 'Ordinance', // Förordning
   Regulation: 'Regulation', // Föreskrift
   GeneralAdvice: 'General advice',
   Guidance: 'Guidance',
   IndustryDemand: 'Industry demand',
   InternalDemand: 'Internal demand',
   DemandType: 'Type of demand',
   Origin: 'Origin',
   Sweden: 'Sweden',
   DemandLevel: 'Demand level',
   PublicAuthorityDemand: 'Public authority demand',
   InsuranceDemand: 'Insurance demand',
   OtherDemand: 'Other demand',
   DemandArea: 'Demand area',
   Economy: 'Economy',
   Sustainability: 'Sustainability',
   Quality: 'Quality',
   Environment: 'Environment',
   Staff: 'Staff',
   Security: 'Security',
   StatusImport: 'Import status',
   Question: 'Question',
   DemandText: 'Demand text',
   AnswerType: 'Answer type',
   DemandSource: 'Demand source',
   AllDemands: 'All demands',
   UrlText: 'Url text',
   NewSubDemand: 'New subdemand',
   ParentDemand: 'Parent demand',
   SubDemands: 'Child demands',
   DemandArchiveWarningText: 'Are you sure you want to archive this demand with underlying information?',
   DemandDeleteWarningText: 'Are you sure you want to delete this demand?',
   ArchivedDemands: 'Archived demands',
   Activate: 'Activate',
   DemandActivateWarningText: 'Are you sure you want to activate this demand with underlying information again?',
   ActiveDemands: 'Active demands',
   ActiveQuestions: 'Active questions',
   ImportedDemands: 'Imported demands',
   Version: 'Version',
   NoArchivedDemands: 'There are no archived demands',
   ShowMore: 'Show more',
   ShowLess: 'Show less',
   of: 'of',
   EnterURL: 'Enter URL',
   LawDemandIsBasedOnHasChanged: 'The law this demand is based on has changed',
   AcknowledgeChange: 'Acknowledge change',
   ChooseParentDemand: 'Choose parent demand',
   NewParentDemand: 'New parent demand',
   SetParentDemand: 'Set parent demand',

   // Validation
   Warning: 'Warning',
   SendToDemandList: 'Send to list of demands',
   Save: 'Save',
   Cancel: 'Cancel',
   Clear: 'Clear',
   Undo: 'Undo',
   Delete: 'Delete',
   Confirm: 'Confirm',
   SavedSuccess: 'Save was successful',
   SavedError: 'Could not save',
   SavedDemands: 'Demands were properly saved',
   DemandsSent: 'Demands were successfully sent to the list of demands',
   DemandMoveError: 'Failed to move demands',
   DemandFetchError: 'Failed to get demand',
   QuestionSaved: 'Question was successfully saved',
   DemandSuccessfullyCleared: 'The ongoing demand handling was successfully cleared',
   DemandClearingFailed: 'Something went wrong when the ongoing claim handling was to be cleared and reset',
   RequiredField: 'This field is required',
   UndoDemandChanges: 'Do you want to undo your changes on this demand?',
   LatestChangesWillBeUndone: 'Changes will be reset.',
   SaveBeforeProceed: 'Save your changes before proceeding',
   FormHasUnsavedChanges: 'The form has unsaved changes. Please save them before proceeding',
   AreYouSureLatestChangesWillBeUndone:
      'Are you sure you want to continue? Your latest changes will be reset. If you want to save your changes, click cancel and save the form before proceeding',
   UndoQuestionChanges: 'Do you want to reset your changes in this question?',
   UndoChanges: 'Undo changes',
   Continue: 'Continue',
   FetchFailed: 'Fetch failed',
   ItemDeleted: 'Item was successfully deleted',
   DeleteError: 'Item could not be deleted',
   DeleteAttachment: 'Delete attachment',
   CantChangeParentByDND: 'You can not change the parent of the item by drag and drop',
   CantDNDOnArchived: 'You can not change the order of archived items',
   CantChangeParentOrLevelByDND: 'You can not change the parent or level of the item by drag and drop',
   ActionNotSupported: 'Action not supported',
   CouldNotFetchQuestions: 'Something went wrong when fetching questions',
   CouldNotFetchLaw: 'Something went wrong when fetching the law',
   LawDoesNotContainParagraphs: 'The selected law does not contain any paragraphs.',
   ParagraphsCouldNotBeFetched: 'No paragraphs could be fetched.',
   QuestionDeleted: 'Question is deleted',
   CouldNotAcknowledgeChange: 'Could not acknowledge change for the law ',
   CouldNotFetchDemand: 'Something went wrong when fetching the demand',
   ConfirmAcknowledgeChange: 'Confirm that you acknowledge the change',
   ConfirmAcknowledgeChangeText:
      'The import status will be reset and the message about changes in the law will disappear. This applies to the law with sfs number: ',
   CouldntExpandRow: "Couldn't expand row",
   LawStatusError: 'The laws status could not be updated',
   LawUpdated: 'The law was updated',
   ReviewPerformed: 'Review performed',
   SomethingWentWrong: 'Something went wrong',
   CouldntFetchDashboardData: "Couldn't fetch dashboard data",
   DNDSuccess: 'Order of demands has been updated',
   DNDFail: 'Could not change order of demands',

   // Question
   QuestionsForDemand: 'Questions for demand',
   NewQuestion: 'New question',
   BackgroundInformation: 'Background fact',
   ProposedAction: '365Robust tips',
   OrganisationType: 'Organisation type',
   OrganisationSize: 'Organisation size',
   Process: 'Process',
   AlternativeProcesses: 'Alternative processes',
   SNI: 'SNI',
   SNI2007: 'SNI 2007',
   SNI2025: 'SNI 2025',
   AdditionalModules: 'Additional modules',
   Subscription: 'Subscription',
   Attachment: 'Attachment',
   AddFile: 'Add file',
   NoQuestionsCreated: 'There are no questions connected to this demand',
   NoQuestionChosen: 'Choose a question to view the content',
   DeleteAttachmentText: 'Are you sure you want to delete attachment?',
   QuestionWithCombinationAlreadyExists: 'A question with a combination of these values already exists.',
   ShowQuestion: 'Show question number',
   LoadingQuestions: 'Loading questions',

   //AnswerOptions
   YesNo: 'Yes/No',
   YesNoDontKnow: 'Yes/No/Dont know',
   Compliance: 'Compliance',
   OwnAnswers: 'Own answers',
   AddAnswerOption: 'Add answer option',
   AnswerOption: 'Answer option',
   Info: 'Info',
   Value: 'Value',
   DemandConnection: 'Demand connection',
   UndoAnswerOptionChanges: 'Do you want to undo your changes on this answer option?',
   Catalog: 'Catalog',
   AnswerOptionList: 'List for answer options',
   Order: 'Order',
   CreateNewAnswerOption: 'New answer option',
   ConnectDemandToAnswer: 'Should demand be able to be linked to this answer option?',
   SelectAnAnswerOption: 'Select an answer option',
   SelectAnOption: 'Select an option',
   SelectOneOrMoreOptions: 'Select one or more options',
   DeleteDemandConnection: 'Delete demand connection',
   ExportedSuccess: 'Successfully exported',
   ExportFail: 'Exporting failed',
   ExportingDemandsAndQuestions: 'Exporting demands and questions',
   PreparingExcel: 'Preparing excel',
   FetchingDemands: 'Fetching demands',
   FetchingQuestions: 'Fetching questions',
   GeneratingExcel: 'Generating excel',
   ChangedTo: 'Changed to',

   //Labels / Placeholders
   Search: 'Search',
   Designation: 'Designation',
   ExpandAll: 'Expand all',
   MiniMizeAll: 'Minimize',
   SortOnColumn: 'Sort on column',
   FilterOnProcessStatus: 'Filter on process status',
   FilterOnImportStatus: 'Filter on import status',
   LawsWithFilter: 'laws with filter',
   LawsWithoutFilter: 'laws without filter',
   Valuation: 'Valuation',

   //Modal header / Modal content
   MoveDemand: 'Move demand',
   MoveTargetTo: 'Move to...',
   DemandModalSearchParams: 'Search for ID, web text or designation',
   EmptySearchResult: 'No demands matching the serach critera...',
   SearchForLevelOneDemands: 'Search for level 1 demands',
   SearchForLevelTwoDemands: 'Search for level 2 demands',
   ChangeOrder: 'Change order',

   //Buttons
   FastMoveDemand: 'Move demand',
   AbortFastMove: 'Abort',
   MoveTo: 'Target',
   Move: 'Move',
   ExportDemandsAndQuestionsToExcel: 'Export to excel',
   ResetFilter: 'Reset filter',
   Previous: 'Previous',
   Next: 'Next',
   DemandsHaveBeenReviwed: 'The demands have been reviewed',
   KeepRelevanceStatus: 'Keep relevance status',

   // Dashboard
   Welcome: 'Welcome',
   OngoingActivities: 'Ongoing activities',
   Activity: 'Activity',
   StatusLaws: 'Status on laws',
   LatestUpdate: 'Latest update',
   NumberOfCustomers: 'Number of customers',
   NumberOfQuestions: 'Number of questions',
   new: 'new',
   revoked: 'revoked',
   updated: 'updated',
   Changed: 'Changed',

   // Information texts
   NoAnswerOptions: 'There are no options. To create options, go to',
   NoAnswerOptionsLink: 'System settings -> Answer option',

   // Demand compliance modules
   WORK_ENVIRONMENT: 'Work environment',
   GDPR: 'GDPR',
   ISO27001: 'ISO 27 001',
   ISO28000: 'ISO 28 000',
   ISO31000: 'ISo 31 000',
   ISO45000: 'ISO 45 000',
   SSF_CYBER_SECURITY: 'SSF Cyber security',
   SYSTEMATIC_FIRE_PROTECTION_WORK: 'Systematic fire protection work',
   SECURITY_PROTECTION: 'Security protection',
   EXTENDED_LEVEL: 'Extended level',
}
