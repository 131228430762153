import React, { useContext } from 'react'
import { Card, Button, makeStyles, tokens, shorthands, mergeClasses, MessageBar, MessageBarBody, MessageBarTitle, Label } from '@fluentui/react-components'
import { t } from '../../i18n/i18n'
import { Demand, DemandComplianceModules, QuestionStatus, SNIVersion } from '../../api/schemas/schema'
import { enumValuesToOptions, valuationOptions } from '../../helpers/enumHelper'
import { QuestionFormValues, getMatchinQuestions } from '../../helpers/questionHelper'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { ControlledDropDown } from '../common/inputs/Dropdown'
import { DropdownMulti } from '../common/inputs/DropdownMulti'
import { FileInput } from '../common/inputs/FileInput'
import { SniDropdown } from '../common/inputs/SniDropdown'
import { DataContext } from '../../App'
import { UseFormReturn } from 'react-hook-form'
import { ProcessDropdown } from './ProcessDropdown'
import AnswerOptionInQuestion from './AnswerOptionInQuestion'
import { ControlledRTFInput } from '../common/inputs/ControlledRTFInput'
import dayjs from 'dayjs'
const useStyles = makeStyles({
   marginRight: {
      marginRight: tokens.spacingHorizontalXL,
   },
   marginLeft: {
      marginLeft: tokens.spacingHorizontalXXXL,
   },
   marginTop: {
      marginTop: tokens.spacingVerticalXS,
   },
   margin: {
      marginTop: tokens.spacingVerticalXS,
      marginLeft: tokens.spacingHorizontalXXXL,
   },
   greyCard: {
      backgroundColor: tokens.colorNeutralBackground4,
   },
   smallGap: {
      ...shorthands.gap(tokens.spacingHorizontalS),
      '@media screen and (min-width: 600px)': {
         ...shorthands.gap(tokens.spacingHorizontalS),
      },
      '& > *': {
         marginBottom: tokens.spacingVerticalMNudge,
      },
   },
   flexColumn: {
      display: 'flex',
      flexDirection: 'column',
   },
   flexEnd: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
   boldLabel: {
      fontWeight: tokens.fontWeightSemibold,
   },
})
type QuestionCardProps = {
   questionForm: UseFormReturn<QuestionFormValues, any, undefined>
   isSubmitting: boolean
   setOpenModal: React.Dispatch<React.SetStateAction<'new' | 'badgeUndo' | 'undo'>>
   isDisabled: boolean
   parentDemand: Demand
}
export const QuestionCard = ({ questionForm, isSubmitting, setOpenModal, isDisabled, parentDemand }: QuestionCardProps) => {
   const dataContext = useContext(DataContext)
   const { subjects, leadershipPerspectives, organisationSizes, organisationTypes, snis_2007, snis_2025, processes } = dataContext.state
   const classes = useStyles()
   const {
      control,
      watch,
      formState: { isDirty },
   } = questionForm
   const existingMatchingQuestions = getMatchinQuestions(watch('questions'), watch('selectedQuestion'), parentDemand)
   const isQuestionValuesUnique = existingMatchingQuestions.length === 0
   const questionIsDefinite = watch('selectedQuestion.status') === QuestionStatus.Definite
   const questionIsArchived = watch('selectedQuestion.status') === QuestionStatus.Archived
   return (
      <Card appearance="subtle" className={classes.greyCard}>
         {!isQuestionValuesUnique && (
            <MessageBar intent="error">
               <MessageBarBody>
                  <MessageBarTitle>{t('QuestionWithCombinationAlreadyExists')}</MessageBarTitle>
                  {t('ShowQuestion')} {existingMatchingQuestions.map((x) => x.order).join(', ')}
               </MessageBarBody>
            </MessageBar>
         )}
         <Grid>
            <GridItem className={mergeClasses(classes.flexColumn, classes.smallGap)} size="6/12">
               <ControlledRTFInput
                  name={'selectedQuestion.text'}
                  control={control}
                  label={t('Question')}
                  isDisabled={isDisabled || isSubmitting}
                  required
                  watchedValue={watch('selectedQuestion.text')}
               />
               <AnswerOptionInQuestion questionForm={questionForm} isDisabled={isDisabled || isSubmitting} questionIsDefinite={questionIsDefinite} />
               <ControlledRTFInput
                  name={'selectedQuestion.backgroundInformation'}
                  control={control}
                  label={t('BackgroundInformation')}
                  isDisabled={isDisabled || isSubmitting}
                  watchedValue={watch('selectedQuestion.backgroundInformation')}
               />
               <ControlledRTFInput
                  name={'selectedQuestion.proposedAction'}
                  control={control}
                  label={t('ProposedAction')}
                  isDisabled={isDisabled || isSubmitting}
                  watchedValue={watch('selectedQuestion.proposedAction')}
               />
            </GridItem>
            <GridItem size="6/12">
               <Grid className={classes.smallGap}>
                  <GridItem size="12/12">
                     <DropdownMulti
                        name={'selectedQuestion.organisationSizes'}
                        control={control}
                        label={t('OrganisationSize')}
                        items={organisationSizes}
                        questionId={watch('selectedQuestion.id')}
                        modelName="organisationSize"
                        disabled={isDisabled || isSubmitting}
                        validationState={!isQuestionValuesUnique ? 'error' : null}
                        isRequired={questionIsDefinite}
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <DropdownMulti
                        name={'selectedQuestion.organisationTypes'}
                        control={control}
                        label={t('OrganisationType')}
                        items={organisationTypes}
                        questionId={watch('selectedQuestion.id')}
                        modelName="organisationType"
                        disabled={isDisabled || isSubmitting}
                        validationState={!isQuestionValuesUnique ? 'error' : null}
                        sortOnLocale
                        isRequired={questionIsDefinite}
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <SniDropdown
                        sniVersion={SNIVersion.SNI2007}
                        questionForm={questionForm}
                        label={t('SNI2007')}
                        items={snis_2007}
                        disabled={isDisabled || isSubmitting}
                        validationState={!isQuestionValuesUnique ? 'error' : null}
                        isRequired={questionIsDefinite}
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <SniDropdown
                        sniVersion={SNIVersion.SNI2025}
                        questionForm={questionForm}
                        label={t('SNI2025')}
                        items={snis_2025}
                        disabled={isDisabled || isSubmitting}
                        validationState={!isQuestionValuesUnique ? 'error' : null}
                        isRequired={questionIsDefinite}
                     />
                  </GridItem>
                  <GridItem size="4/12">
                     <ProcessDropdown
                        control={control}
                        questionId={watch('selectedQuestion.id')}
                        alternativeProcessIds={watch('selectedQuestion.alternativeProcesses')?.map((ap) => ap.processId)}
                        disabled={isDisabled || isSubmitting}
                        isRequired={questionIsDefinite}
                     />
                  </GridItem>
                  <GridItem size="8/12">
                     <DropdownMulti
                        control={control}
                        name={'selectedQuestion.alternativeProcesses'}
                        items={processes}
                        label={t('AlternativeProcesses')}
                        questionId={watch('selectedQuestion.id')}
                        modelName="process"
                        disabledOption={watch('selectedQuestion.process.process.text')}
                        disabled={isDisabled || isSubmitting}
                        sortOnLocale
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <DropdownMulti
                        name={'selectedQuestion.leadershipPerspectives'}
                        control={control}
                        label={t('LeadershipPerspective')}
                        items={leadershipPerspectives}
                        questionId={watch('selectedQuestion.id')}
                        modelName="leadershipPerspective"
                        disabled={isDisabled || isSubmitting}
                        sortOnLocale
                        isRequired={questionIsDefinite}
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <DropdownMulti
                        name={'selectedQuestion.subjects'}
                        control={control}
                        label={t('Subject')}
                        items={subjects}
                        questionId={watch('selectedQuestion.id')}
                        modelName="subject"
                        disabled={isDisabled || isSubmitting}
                        sortOnLocale
                        isRequired={questionIsDefinite}
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <ControlledDropDown
                        name={'selectedQuestion.demandComplianceModulesEnum'}
                        control={control}
                        label={t('AdditionalModules')}
                        options={enumValuesToOptions(DemandComplianceModules)}
                        disabled={isDisabled || isSubmitting}
                        multi
                     />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDropDown
                        required
                        control={control}
                        name={'selectedQuestion.valuation'}
                        options={valuationOptions()}
                        label={t('Valuation')}
                        disabled={isDisabled || isSubmitting}
                     />
                  </GridItem>
                  <GridItem size="6/12" className={classes.margin}>
                     <FileInput label={t('Attachment')} questionForm={questionForm} disabled={isDisabled || isSubmitting} />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDropDown
                        required
                        control={control}
                        name={'selectedQuestion.status'}
                        options={enumValuesToOptions(QuestionStatus)
                           .filter((option) => questionIsArchived || option.key !== QuestionStatus.Archived)
                           .map((option) => ({
                              ...option,
                           }))}
                        label={t('Status')}
                        disabled={isDisabled || isSubmitting}
                     />
                  </GridItem>
                  <GridItem size="2/12" className={classes.margin}>
                     <div>
                        <Label className={classes.boldLabel}>{t('ID')}</Label>
                        <p>{watch('selectedQuestion.publicId').toString()}</p>
                     </div>
                  </GridItem>
                  <GridItem size="2/12">
                     <div className={classes.marginTop}>
                        <Label className={classes.boldLabel}>{t('Modified')}</Label>
                        <p>{dayjs(watch('selectedQuestion.modifiedDate')).format('YYYY-MM-DD HH:mm')}</p>
                     </div>
                  </GridItem>
                  <GridItem size="2/12">
                     <div className={classes.marginTop}>
                        <Label className={classes.boldLabel}>{t('ModifiedBy')}</Label>
                        <p>{watch('selectedQuestion.modifiedBy')}</p>
                     </div>
                  </GridItem>

                  {/* UTKOMMENTERAT TILLSVIDARE KAN KOMMA ATT ANVÄNDAS
                  <GridItem size="12/12">
                        <DropdownMulti
                           name={'selectedQuestion.subscriptions'}
                           control={control}
                           label={t('Subscriptions')}
                           items={subscriptions}
                           questionId={watch('selectedQuestion.id')}
                           modelName="subscription"
                           sortOnLocale
                        />
                  </GridItem> */}
               </Grid>
            </GridItem>

            <GridItem size="12/12" className={classes.flexEnd}>
               <Button disabled={!isDirty || isSubmitting} onClick={() => setOpenModal('undo')} className={classes.marginRight}>
                  {t('Undo')}
               </Button>
               <Button disabled={!isDirty || isSubmitting || !isQuestionValuesUnique} appearance="primary" type="submit">
                  {t('Save')}
               </Button>
            </GridItem>
         </Grid>
      </Card>
   )
}
