import React, { createContext, useEffect, useState } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { GetInitialRootState, initialDataContext } from './helpers/ctxHelper'
import { Dashboard } from './pages/Dashboard'
import { SelectedLaw } from './pages/SelectedLaw'
import { Laws } from './pages/Laws'
import { ComplianceOfDemands } from './pages/Demand/ComplianceOfDemands'
import { IRootState } from './interfaces/IRootState'
import { IDataContext } from './interfaces/IDataContext'
import { handleMessage } from './helpers/stateHelper'
import { GetDemands } from './api/demand'
import { t } from './i18n/i18n'
import { DemandQuestion } from './pages/DemandQuestion'
import { AnswerOptionList } from './pages/SystemSettings/AnswerOptionList'
import { Api, SNIVersion } from './api/schemas/schema'
import AnswerOptionForm from './pages/Answer/AnswerOptionForm'
import { clearScrollPosition } from './helpers/scrollPositionHelper'
export const DataContext = createContext(initialDataContext)
const App = () => {
   const [state, setRootState] = useState<IRootState>(GetInitialRootState())

   const dataContextValue: IDataContext = {
      state,
      setRootState,
      handleMessage,
   }
   const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })

   useEffect(() => {
      ;(async () => {
         setRootState((prev) => ({ ...prev, initialLoading: true }))
         try {
            Promise.all([await GetDemands(), (await apiInstance.api.answerOptionList()) as any]).then(([demands, answerOptions]) => {
               setRootState((prev) => ({
                  ...prev,
                  demands,
                  answerOptions: answerOptions.data,
               }))
            })
         } catch (error: any) {
            handleMessage(setRootState, 'error', t('FetchFailed'), error.message)
         } finally {
            setRootState((prev) => ({ ...prev, initialLoading: false }))
         }
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      if (state.initialLoading === false) {
         ;(async () => {
            try {
               Promise.all([
                  (await apiInstance.api.subjectList()).data,
                  (await apiInstance.api.organisationSizeList()).data,
                  (await apiInstance.api.organisationTypeList()).data,
                  (await apiInstance.api.leadershipPerspectiveList()).data,
                  (await apiInstance.api.getApi3({ group: 'main group' })).data,
                  (await apiInstance.api.processList()).data,
               ]).then(([subjects, organisationSizes, organisationTypes, leadershipPerspectives, snis, processes]) => {
                  setRootState((prev) => ({
                     ...prev,
                     subjects,
                     organisationSizes,
                     organisationTypes,
                     leadershipPerspectives,
                     snis_2007: snis.filter((x) => x.version === SNIVersion.SNI2007),
                     snis_2025: snis.filter((x) => x.version === SNIVersion.SNI2025),
                     processes,
                  }))
               })
               clearScrollPosition()
            } catch (error: any) {
               handleMessage(setRootState, 'error', t('FetchFailed'), error.message)
            }
         })()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [state.initialLoading])
   const router = createBrowserRouter([
      {
         path: '/',
         element: <Dashboard />,
         //   loader: <Spinner />,
      },
      {
         path: '/law-import/:id',
         element: <SelectedLaw />,
         //   loader: <Spinner />,
      },
      {
         path: '/law-import',
         element: <Laws />,
         //   loader: <Spinner />,
      },
      {
         path: '/demands/:id',
         element: <DemandQuestion />,
         //   loader: <Spinner />,
      },
      {
         path: '/demands',
         element: <ComplianceOfDemands />,
         //   loader: <Spinner />,
      },
      {
         path: '/system-settings',
         element: <AnswerOptionList />,
         //   loader: <Spinner />,
      },
      {
         path: '/system-settings/answer-options/:id',
         element: <AnswerOptionForm />,
         //   loader: <Spinner />,
      },
   ])
   return (
      <DataContext.Provider value={dataContextValue}>
         <RouterProvider router={router} />
      </DataContext.Provider>
   )
}

export default App
